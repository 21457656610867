var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row maindashboard" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c("div", { staticClass: "col-12 no-padding" }, [
              _c("div", { staticClass: "cardcontents" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(" 무재해 현황 "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "card-body",
                    staticStyle: { height: "213px" },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("apexchart", {
                            ref: "uninjuryChart",
                            attrs: {
                              height: "320px",
                              type: "radialBar",
                              width: _vm.uninjuryChart.chartWidth,
                              options: _vm.uninjuryChart.chartOptions,
                              series: _vm.uninjuryChart.series,
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center !important",
                              },
                            },
                            [
                              _c(
                                "q-chip",
                                {
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    color: "primary",
                                    "text-color": "white",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.uninjuryData.deptName) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-6" }, [
                        _c("ul", { staticClass: "uninjury-area" }, [
                          _c("li", { staticClass: "uninjury-title" }, [
                            _vm._v(" 목표 "),
                          ]),
                          _c("li", { staticClass: "uninjury-data" }, [
                            _c("div", { staticClass: "uninjury-num" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toThousandFilter")(
                                    _vm.uninjuryData.dateTotalCnt
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" 일 "),
                          ]),
                        ]),
                        _c("ul", { staticClass: "uninjury-area" }, [
                          _c("li", { staticClass: "uninjury-title" }, [
                            _vm._v(" 달성 "),
                          ]),
                          _c("li", { staticClass: "uninjury-data" }, [
                            _c("div", { staticClass: "uninjury-num" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toThousandFilter")(
                                    _vm.uninjuryData.dateCnt
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" 일 "),
                          ]),
                        ]),
                        _c("ul", { staticClass: "uninjury-area" }, [
                          _c("li", { staticClass: "uninjury-title" }, [
                            _vm._v(" 시작 "),
                          ]),
                          _c("li", { staticClass: "uninjury-data" }, [
                            _c("div", { staticClass: "uninjury-date" }, [
                              _vm._v(_vm._s(_vm.uninjuryData.startDt)),
                            ]),
                          ]),
                        ]),
                        _c("ul", { staticClass: "uninjury-area" }, [
                          _c("li", { staticClass: "uninjury-title" }, [
                            _vm._v(" 현재 "),
                          ]),
                          _c("li", { staticClass: "uninjury-data" }, [
                            _c("div", { staticClass: "uninjury-date" }, [
                              _vm._v(_vm._s(_vm.$comm.getToday())),
                            ]),
                          ]),
                        ]),
                        _c("ul", { staticClass: "uninjury-area" }, [
                          _c("li", { staticClass: "uninjury-title" }, [
                            _vm._v(" 목표일 "),
                          ]),
                          _c("li", { staticClass: "uninjury-data" }, [
                            _c("div", { staticClass: "uninjury-date" }, [
                              _vm._v(_vm._s(_vm.uninjuryData.targetDt)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-12 no-padding" }, [
              _c("div", { staticClass: "cardcontents" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v(" " + _vm._s(_vm.$label("LBL0000942")) + " "),
                  _c(
                    "div",
                    { staticClass: "card-more main-header-input" },
                    [
                      _c("c-plant", {
                        attrs: {
                          type: "search",
                          name: "plantCdAcc",
                          isFirstValue: false,
                          label: "",
                        },
                        on: { datachange: _vm.getNearmiss },
                        model: {
                          value: _vm.plantCdAcc,
                          callback: function ($$v) {
                            _vm.plantCdAcc = $$v
                          },
                          expression: "plantCdAcc",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "card-body nopadgrid" },
                  [
                    _c("c-table", {
                      ref: "gridAcc",
                      attrs: {
                        tableId: "gridAcc",
                        isTitle: false,
                        isDashboard: true,
                        columnSetting: false,
                        usePaging: false,
                        filtering: false,
                        isFullScreen: false,
                        hideBottom: true,
                        gridHeight: "200px",
                        columns: _vm.gridAcc.columns,
                        data: _vm.gridAcc.data,
                      },
                      on: { linkClick: _vm.linkClickAcc },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c("div", { staticClass: "cardcontents directorMainLayer" }, [
              _c(
                "div",
                { staticClass: "card-header non-label-icon mapheader" },
                [
                  _vm._v(" " + _vm._s("배치도 현황") + " "),
                  _c(
                    "q-chip",
                    {
                      staticClass: "chip-task",
                      attrs: {
                        square: "",
                        clickable: "",
                        outline: _vm.mapObj.activeTask !== 1,
                        color:
                          _vm.mapObj.activeTask === 1 ? "green-6" : "gray-4",
                        "text-color":
                          _vm.mapObj.activeTask === 1 ? "white" : "white",
                        icon: "directions",
                      },
                      on: {
                        click: () => {
                          _vm.mapObj.activeTask = 1
                          _vm.getMapData()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s("작업허가서") + " ")]
                  ),
                  _c(
                    "q-chip",
                    {
                      staticClass: "chip-task",
                      attrs: {
                        square: "",
                        clickable: "",
                        outline: _vm.mapObj.activeTask !== 2,
                        color:
                          _vm.mapObj.activeTask === 2 ? "amber-7" : "gray-4",
                        "text-color":
                          _vm.mapObj.activeTask === 2 ? "white" : "white",
                        icon: "directions",
                      },
                      on: {
                        click: () => {
                          _vm.mapObj.activeTask = 2
                          _vm.getMapData()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s("위험물저장소") + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-more main-header-input main-header-input2",
                    },
                    [
                      _c("c-datepicker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.mapObj.activeTask === 1,
                            expression: "mapObj.activeTask===1",
                          },
                        ],
                        attrs: {
                          range: true,
                          appendToBody: false,
                          name: "workPermitPeriod",
                        },
                        on: { datachange: _vm.getMapData },
                        model: {
                          value: _vm.mapObj.taskParam.workPermitPeriod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mapObj.taskParam,
                              "workPermitPeriod",
                              $$v
                            )
                          },
                          expression: "mapObj.taskParam.workPermitPeriod",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  ref: "carousel",
                  staticClass: "card-body",
                  staticStyle: { padding: "15px !important" },
                },
                [
                  _vm.mapObj.maps && _vm.mapObj.maps.length > 0
                    ? _c(
                        "q-carousel",
                        {
                          staticClass: "workPermit-carousel",
                          attrs: {
                            swipeable: "",
                            animated: "",
                            arrows: "",
                            "control-type": "regular",
                            "control-color": "grey-6",
                            infinite: "",
                          },
                          model: {
                            value: _vm.mapObj.slide,
                            callback: function ($$v) {
                              _vm.$set(_vm.mapObj, "slide", $$v)
                            },
                            expression: "mapObj.slide",
                          },
                        },
                        _vm._l(_vm.mapObj.maps, function (map, idx) {
                          return _c(
                            "q-carousel-slide",
                            {
                              key: idx,
                              attrs: { name: idx, "img-src": map.mapSrc },
                            },
                            [
                              [
                                _c("div", {
                                  staticClass: "mapTitleDiv",
                                  domProps: {
                                    textContent: _vm._s(map.mapName),
                                  },
                                }),
                                _vm.mapObj.activeTask === 1
                                  ? _c("div", { staticClass: "mapWorkIcons" }, [
                                      _c(
                                        "ul",
                                        _vm._l(
                                          _vm.swpIconItems,
                                          function (icon, iconIdx) {
                                            return _c(
                                              "li",
                                              { key: iconIdx },
                                              [
                                                _c("q-icon", {
                                                  staticClass: "text-red",
                                                  attrs: {
                                                    name: icon.icon,
                                                    size: "16px",
                                                  },
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(icon.name) + " "
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.mapObj.activeTask === 1
                                  ? _vm._l(map.maps, function (item, idx) {
                                      return _c(
                                        "VueDraggableResizable",
                                        {
                                          key: idx,
                                          ref: "markImage",
                                          refInFor: true,
                                          staticClass: "mainMarkImage",
                                          class:
                                            item.idx === _vm.mapObj.activeMarked
                                              ? "selected"
                                              : "",
                                          attrs: {
                                            resizable: false,
                                            parent: true,
                                            draggable: false,
                                            x:
                                              item.locationXcoordinate *
                                              _vm.mapRate *
                                              _vm.mapObj.ratio,
                                            y:
                                              item.locationYcoordinate *
                                              _vm.mapRate *
                                              _vm.mapObj.ratio,
                                            w: 40,
                                            h: 40,
                                            grid: [20, 20],
                                          },
                                          on: {
                                            activated: function ($event) {
                                              return _vm.onMarkedInfo(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("c-map-marker", {
                                            attrs: {
                                              isSelected:
                                                item.idx ===
                                                _vm.mapObj.activeMarked,
                                            },
                                          }),
                                          _c(
                                            "q-menu",
                                            {
                                              ref: "markedproxy" + item.idx,
                                              refInFor: true,
                                              attrs: {
                                                "content-class":
                                                  "mainMapMenuLayer",
                                                anchor: "top right",
                                                self: "top left",
                                              },
                                            },
                                            _vm._l(
                                              _vm.mapObj.selectedWorkPermit
                                                .workPermits,
                                              function (workPermit, idx) {
                                                return _c(
                                                  "q-card",
                                                  {
                                                    key: idx,
                                                    attrs: {
                                                      flat: "",
                                                      bordered: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openWorkPermitPop(
                                                          workPermit
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "q-item",
                                                      {
                                                        staticClass:
                                                          "main-work-permit-card-section main-work-permit-card-item",
                                                      },
                                                      [
                                                        _c(
                                                          "q-item-section",
                                                          {
                                                            attrs: {
                                                              avatar: "",
                                                            },
                                                          },
                                                          [
                                                            _c("q-badge", {
                                                              attrs: {
                                                                rounded: "",
                                                                color:
                                                                  _vm.setStepColor(
                                                                    workPermit.swpStepCd
                                                                  ),
                                                                label:
                                                                  workPermit.swpStepName,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              _vm._l(
                                                                _vm.setTypeIcons(
                                                                  workPermit.sopWorkTypeCds
                                                                ),
                                                                function (
                                                                  icon,
                                                                  iconIdx
                                                                ) {
                                                                  return _c(
                                                                    "q-icon",
                                                                    {
                                                                      key: iconIdx,
                                                                      staticClass:
                                                                        "text-red",
                                                                      attrs: {
                                                                        name: icon,
                                                                        size: "25px",
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-card-section",
                                                      {
                                                        staticClass:
                                                          "main-work-permit-card-section",
                                                      },
                                                      [
                                                        _c("q-icon", {
                                                          staticClass:
                                                            "text-indigo",
                                                          attrs: {
                                                            name: "chevron_right",
                                                          },
                                                        }),
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              workPermit.workSummary
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              workPermit.workTime
                                                            ) +
                                                            ") "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    })
                                  : _vm.mapObj.activeTask === 2
                                  ? _vm._l(
                                      map.dangerMaps,
                                      function (item, idx) {
                                        return _c(
                                          "VueDraggableResizable",
                                          {
                                            key: "other" + idx,
                                            ref: "otherMarkImage",
                                            refInFor: true,
                                            staticClass: "mainMarkImage",
                                            class:
                                              item.idx ===
                                              _vm.mapObj.activeMarked2
                                                ? "selected"
                                                : "",
                                            attrs: {
                                              resizable: false,
                                              parent: true,
                                              draggable: false,
                                              x:
                                                item.locationXcoordinate *
                                                _vm.mapObj.ratio,
                                              y:
                                                item.locationYcoordinate *
                                                _vm.mapObj.ratio,
                                              w: 30,
                                              h: 30,
                                              grid: [30, 30],
                                            },
                                            on: {
                                              activated: function ($event) {
                                                return _vm.onMarkedInfo2(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("c-map-marker", {
                                              attrs: {
                                                isSelected:
                                                  item.idx ===
                                                  _vm.mapObj.activeMarked2,
                                              },
                                            }),
                                            _c(
                                              "q-menu",
                                              {
                                                ref: "markedproxy2_" + item.idx,
                                                refInFor: true,
                                                attrs: {
                                                  "content-class":
                                                    "mainMapMenuLayer",
                                                  anchor: "top right",
                                                  self: "top left",
                                                },
                                              },
                                              [
                                                _c(
                                                  "q-card",
                                                  {
                                                    class: [
                                                      "closedSpace-menu-card",
                                                    ],
                                                    attrs: {
                                                      flat: "",
                                                      bordered: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.linkClickDanger(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "q-item",
                                                      {
                                                        staticClass:
                                                          "closedSpace-card-section closedSpace-card-item",
                                                      },
                                                      [
                                                        _c(
                                                          "q-item-section",
                                                          [
                                                            _c(
                                                              "q-item-label",
                                                              [
                                                                _c("q-badge", {
                                                                  attrs: {
                                                                    rounded: "",
                                                                    color:
                                                                      "light-blue",
                                                                    label:
                                                                      item.chmDangerArea,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-card-section",
                                                      {
                                                        staticClass:
                                                          "closedSpace-card-section cursor-pointer",
                                                      },
                                                      [
                                                        _c("q-icon", {
                                                          staticClass:
                                                            "text-indigo",
                                                          attrs: {
                                                            name: "chevron_right",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              "구분: " +
                                                                item.chmDangerTypeName
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              "품명 표기: " +
                                                                item.chmDangerMstName
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  : _vm._e(),
                              ],
                            ],
                            2
                          )
                        }),
                        1
                      )
                    : _c("el-empty", {
                        attrs: {
                          "image-size": 312,
                          description: "사업장에 등록된 지도가 없습니다.",
                        },
                      }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 no-padding",
          },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" " + _vm._s(_vm.$label("LBL0000943")) + " "),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-plant", {
                      attrs: {
                        type: "search",
                        label: "",
                        isFirstValue: false,
                        name: "plantCd4",
                      },
                      on: { datachange: _vm.datachange4 },
                      model: {
                        value: _vm.plantCd4,
                        callback: function ($$v) {
                          _vm.plantCd4 = $$v
                        },
                        expression: "plantCd4",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-more main-header-input main-header-input2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        type: "year",
                        default: "today",
                        name: "actionCompleteRequestDate",
                      },
                      on: { input: _vm.getImprStatus },
                      model: {
                        value: _vm.actionCompleteRequestDate,
                        callback: function ($$v) {
                          _vm.actionCompleteRequestDate = $$v
                        },
                        expression: "actionCompleteRequestDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("apexchart", {
                    ref: "imprChart",
                    attrs: {
                      height: "300px",
                      type: "bar",
                      width: _vm.imprChart.chartWidth,
                      options: _vm.imprChart.chartOptions,
                      series: _vm.imprChart.series,
                    },
                    on: { dataPointSelection: _vm.selectedBarImp },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c("div", { staticClass: "cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 위험성평가(위험등록부) "),
                _c(
                  "div",
                  { staticClass: "card-more main-header-input" },
                  [
                    _c("c-select", {
                      attrs: {
                        type: "none",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "risk",
                        label: "",
                        comboItems: _vm.afterRiskItems,
                      },
                      on: { datachange: _vm.getRiskStatus },
                      model: {
                        value: _vm.risk,
                        callback: function ($$v) {
                          _vm.risk = $$v
                        },
                        expression: "risk",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-more main-header-input main-header-input2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        type: "year",
                        default: "today",
                        name: "riskYear",
                      },
                      on: { input: _vm.getRiskStatus },
                      model: {
                        value: _vm.riskYear,
                        callback: function ($$v) {
                          _vm.riskYear = $$v
                        },
                        expression: "riskYear",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body nopadgrid" },
                [
                  _c("c-table", {
                    ref: "gridRisk",
                    attrs: {
                      tableId: "gridRisk",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "313px",
                      columns: _vm.gridRisk.columns,
                      data: _vm.gridRisk.data,
                    },
                    on: { linkClick: _vm.linkClickRisk },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-notice-popup"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }