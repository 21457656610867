
<template>
  <div>
    <div class="row maindashboard">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              무재해 현황
            </div>
            <div class="card-body"  style="height: 213px">
              <div class="row">
                <div class="col-6">
                  <apexchart 
                    ref="uninjuryChart" 
                    height="320px" 
                    type="radialBar"
                    :width="uninjuryChart.chartWidth"
                    :options="uninjuryChart.chartOptions" 
                    :series="uninjuryChart.series">
                  </apexchart>
                  <div style="text-align: center !important;">
                    <q-chip outline square color="primary" text-color="white">
                      {{ uninjuryData.deptName }}
                    </q-chip>
                  </div>
                </div>
                <div class="col-6">
                  <ul class="uninjury-area">
                    <li class="uninjury-title">
                      목표
                    </li>
                    <li class="uninjury-data">
                      <div class="uninjury-num">{{ uninjuryData.dateTotalCnt | toThousandFilter }}</div> 일
                    </li>
                  </ul>
                  <ul class="uninjury-area">
                    <li class="uninjury-title">
                      달성
                    </li>
                    <li class="uninjury-data">
                      <div class="uninjury-num">{{ uninjuryData.dateCnt | toThousandFilter }}</div> 일
                    </li>
                  </ul>
                  <ul class="uninjury-area">
                    <li class="uninjury-title">
                      시작
                    </li>
                    <li class="uninjury-data">
                      <div class="uninjury-date">{{ uninjuryData.startDt }}</div>
                    </li>
                  </ul>
                  <ul class="uninjury-area">
                    <li class="uninjury-title">
                      현재
                    </li>
                    <li class="uninjury-data">
                      <div class="uninjury-date">{{ $comm.getToday() }}</div>
                    </li>
                  </ul>
                  <ul class="uninjury-area">
                    <li class="uninjury-title">
                      목표일
                    </li>
                    <li class="uninjury-data">
                      <div class="uninjury-date">{{ uninjuryData.targetDt }}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              <!-- 아차사고 현황 -->
              {{$label('LBL0000942')}}
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="plantCdAcc"
                  :isFirstValue="false"
                  label=""
                  v-model="plantCdAcc"
                  @datachange="getNearmiss"/>
              </div>
            </div>
            <div class="card-body nopadgrid">
              <c-table
                ref="gridAcc"
                tableId="gridAcc"
                :isTitle="false"
                :isDashboard="true"
                :columnSetting="false"
                :usePaging="false"
                :filtering="false"
                :isFullScreen="false"
                :hideBottom="true"
                gridHeight="200px"
                :columns="gridAcc.columns"
                :data="gridAcc.data"
                @linkClick="linkClickAcc"
              >
              </c-table>
            </div>
          </div>
          <!-- <div class="cardcontents">
            <div class="card-header">
              {{$label('LBL0000941')}}
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="plantCd3"
                  :isFirstValue="false"
                  label=""
                  v-model="plantCd3"
                  @datachange="getImprProgress"/>
              </div>
            </div>
            <div class="card-body nopadgrid">
              <c-table
                ref="grid2"
                tableId="grid2"
                :isTitle="false"
                :isDashboard="true"
                :columnSetting="false"
                :usePaging="false"
                :filtering="false"
                :isFullScreen="false"
                :hideBottom="true"
                gridHeight="217px"
                :columns="grid2.columns"
                :data="grid2.data"
                @linkClick="linkClickImp"
              >
              </c-table>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <div class="cardcontents directorMainLayer">
          <div class="card-header non-label-icon mapheader">
            {{'배치도 현황'}}
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==1"
              :color="mapObj.activeTask===1 ? 'green-6' : 'gray-4'" 
              :text-color="mapObj.activeTask===1 ? 'white' : 'white'" 
              icon="directions"
              @click="() => {mapObj.activeTask = 1; getMapData()}">
              {{'작업허가서'}}
            </q-chip>
            <q-chip class="chip-task"
              square clickable
              :outline="mapObj.activeTask!==2"
              :color="mapObj.activeTask===2 ? 'amber-7' : 'gray-4'" 
              :text-color="mapObj.activeTask===2 ? 'white' : 'white'" 
              icon="directions"
              @click="() => {mapObj.activeTask = 2; getMapData()}">
              {{'위험물저장소'}}
            </q-chip>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                v-show="mapObj.activeTask===1"
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
              <!-- <c-select
                v-show="mapObj.activeTask===2"
                codeGroupCd="CLOSED_SPACE_TYPE_CD"
                type="search"
                itemText="codeName"
                itemValue="code"
                name="closedSpaceTypeCd"
                label=""
                v-model="mapObj.taskParam.closedSpaceTypeCd"
                @datachange="getMapData"
                ></c-select> -->
              <!-- <c-datepicker
                v-show="mapObj.activeTask===2"
                :range="true"
                :appendToBody="false"
                name="closedSpacePeriod"
                v-model="mapObj.taskParam.closedSpacePeriod"
                @datachange="getMapData"
              /> -->
            </div>
          </div>
          <div class="card-body" ref="carousel" style="padding: 15px !important;">
            <q-carousel
              v-if="mapObj.maps && mapObj.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              control-type="regular"
              control-color="grey-6"
              v-model="mapObj.slide"
              infinite
            >
              <q-carousel-slide 
                v-for="(map, idx) in mapObj.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div v-if="mapObj.activeTask===1" class="mapWorkIcons">
                    <ul>
                      <li v-for="(icon, iconIdx) in swpIconItems"
                        :key="iconIdx"
                      >
                        <q-icon 
                          :name="icon.icon" 
                          class="text-red"
                          size="16px" />
                        {{ icon.name }}
                      </li>
                    </ul>
                  </div>
                  <!-- 작업허가서 -->
                  <template v-if="mapObj.activeTask===1">
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :class="item.idx === mapObj.activeMarked ? 'selected' : ''"
                      :resizable="false"
                      :parent="true"
                      :draggable="false"
                      :x="item.locationXcoordinate * mapRate * mapObj.ratio"
                      :y="item.locationYcoordinate * mapRate * mapObj.ratio"
                      :w="40 " :h="40"
                      :grid="[20, 20]"
                      @activated="onMarkedInfo(item)"
                    >
                      <c-map-marker :isSelected="item.idx === mapObj.activeMarked" />
                      <q-menu 
                        :ref="'markedproxy' + item.idx" 
                        :content-class="'mainMapMenuLayer'"
                        anchor="top right"
                        self="top left">
                        <q-card 
                          v-for="(workPermit, idx) in mapObj.selectedWorkPermit.workPermits"
                          :key="idx"
                          flat bordered 
                          @click="openWorkPermitPop(workPermit)"
                        >
                          <q-item class="main-work-permit-card-section main-work-permit-card-item">
                            <q-item-section avatar>
                              <q-badge rounded :color="setStepColor(workPermit.swpStepCd)" :label="workPermit.swpStepName" />
                            </q-item-section>
                            <q-item-section>
                              <q-item-label>
                                <q-icon 
                                  v-for="(icon, iconIdx) in setTypeIcons(workPermit.sopWorkTypeCds)"
                                  :key="iconIdx"
                                  :name="icon" 
                                  class="text-red"
                                  size="25px" />
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                          <q-card-section class="main-work-permit-card-section">
                            <q-icon 
                              name="chevron_right" 
                              class="text-indigo" 
                            />
                            <b>{{workPermit.workSummary}}</b> ({{workPermit.workTime}})
                          </q-card-section>
                        </q-card>
                      </q-menu>
                    </VueDraggableResizable>
                  </template>
                  <!-- 위험물저장소 배치도 -->
                  <template v-else-if="mapObj.activeTask===2">
                    <VueDraggableResizable
                      v-for="(item, idx) in map.dangerMaps"
                      :key="'other' + idx"
                      ref="otherMarkImage"
                      class="mainMarkImage"
                      :class="item.idx === mapObj.activeMarked2 ? 'selected' : ''"
                      :resizable="false"
                      :parent="true"
                      :draggable="false"
                      :x="item.locationXcoordinate * mapObj.ratio"
                      :y="item.locationYcoordinate * mapObj.ratio"
                      :w="30" :h="30"
                        :grid="[30, 30]"
                      @activated="onMarkedInfo2(item)"
                    >
                      <c-map-marker :isSelected="item.idx === mapObj.activeMarked2" />
                      <q-menu 
                        :ref="'markedproxy2_' + item.idx" 
                        :content-class="'mainMapMenuLayer'"
                        anchor="top right"
                        self="top left">
                        <q-card 
                          flat bordered 
                          :class="['closedSpace-menu-card']"
                          @click="linkClickDanger(item)">
                          <q-item class="closedSpace-card-section closedSpace-card-item">
                            <q-item-section>
                              <q-item-label>
                                <q-badge rounded color="light-blue" :label="item.chmDangerArea" /> 
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                          <q-card-section class="closedSpace-card-section cursor-pointer">
                            <q-icon 
                              name="chevron_right" 
                              class="text-indigo" 
                            />
                            {{ '구분: ' + item.chmDangerTypeName}}
                            <br>
                            {{ '품명 표기: ' + item.chmDangerMstName }}
                          </q-card-section>
                        </q-card>
                      </q-menu>
                    </VueDraggableResizable>
                  </template>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 등록된 지도가 없습니다. -->
            <el-empty v-else 
              :image-size="312"
              description="사업장에 등록된 지도가 없습니다."></el-empty>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 no-padding">
        <div class="cardcontents">
          <div class="card-header">
            <!-- 통합개선 이행율 현황 -->
            {{$label('LBL0000943')}}
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                label=""
                :isFirstValue="false"
                name="plantCd4"
                v-model="plantCd4"
                @datachange="datachange4" />
            </div>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                type="year"
                default="today"
                name="actionCompleteRequestDate"
                @input="getImprStatus"
                v-model="actionCompleteRequestDate"
              />
            </div>
          </div>
          <div class="card-body">
            <apexchart 
              ref="imprChart" 
              height="300px" 
              type="bar"
              @dataPointSelection="selectedBarImp"
              :width="imprChart.chartWidth"
              :options="imprChart.chartOptions" 
              :series="imprChart.series"></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <div class="cardcontents">
          <div class="card-header">
            위험성평가(위험등록부)
            <div class="card-more main-header-input">
              <c-select
                type="none"
                itemText="codeName"
                itemValue="code"
                name="risk"
                label=""
                :comboItems="afterRiskItems"
                v-model="risk"
                @datachange="getRiskStatus"
              ></c-select>
            </div>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                type="year"
                default="today"
                name="riskYear"
                @input="getRiskStatus"
                v-model="riskYear"
              />
            </div>
          </div>
          <div class="card-body nopadgrid">
            <c-table
              ref="gridRisk"
              tableId="gridRisk"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="313px"
              :columns="gridRisk.columns"
              :data="gridRisk.data"
              @linkClick="linkClickRisk"
            >
            <!-- <template v-slot:customArea="{ props, col }">
              <template v-if="col.name==='customCol'">
                <component
                  :is="imprComponent"
                  :col="col"
                  :props="props"
                  :inputEditable="false"
                  :isImmShow="true"
                  :requestContentsCols="requestContentsCols"
                  tableKey="selfInspectionResultItemId"
                  ibmTaskTypeCd="ITT0000020"
                  ibmTaskUnderTypeCd="ITTU000025"
                  @imprChange="imprChange"
                />
              </template>
              <template v-if="col.name==='afterRisk'">
                <div class="description-td">
                   <font class="text-negative" style="font-size:1.4em;font-weight:500;">{{props.row['afterRisk']}}</font>
                </div>
              </template>
            </template> -->
            </c-table>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-notice-popup></c-notice-popup>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  name: 'main-dashboard-director',
  components: { 
    VueDraggableResizable,
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      afterRiskItems: [
        { code: 1, codeName: "개선후 위험도 1 이상" },
        { code: 2, codeName: "개선후 위험도 2 이상" },
        { code: 3, codeName: "개선후 위험도 3 이상" },
        { code: 4, codeName: "개선후 위험도 4 이상" },
        { code: 5, codeName: "개선후 위험도 5 이상" },
        { code: 6, codeName: "개선후 위험도 6 이상" },
        { code: 7, codeName: "개선후 위험도 7 이상" },
        { code: 8, codeName: "개선후 위험도 8 이상" },
        { code: 9, codeName: "개선후 위험도 9 이상" },
        { code: 10, codeName: "개선후 위험도 10 이상" },
        { code: 11, codeName: "개선후 위험도 11 이상" },
        { code: 12, codeName: "개선후 위험도 12 이상" },
        { code: 13, codeName: "개선후 위험도 13 이상" },
        { code: 14, codeName: "개선후 위험도 14 이상" },
        { code: 15, codeName: "개선후 위험도 15 이상" },
        { code: 16, codeName: "개선후 위험도 16 이상" },
        { code: 17, codeName: "개선후 위험도 17 이상" },
        { code: 18, codeName: "개선후 위험도 18 이상" },
        { code: 19, codeName: "개선후 위험도 19 이상" },
        { code: 20, codeName: "개선후 위험도 20 이상" },
      ],
      settingSeconds: 5,
      fullscreen: false,
      riskYear: '',
      risk: 9,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      uninjuryData: {
        startDt: '',
        targetExpectedDt: '',
        attainDay: '',
        targetDay: '',
        today: '',
        deptName: '',
      },
      plantCd1: null,
      plantCd3: null,
      plantCd4: null,
      plantCdAcc: null,
      yearOcuurChart: {
        chartOptions: {
          chart: {
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          title: {
            text: '',
            align: 'left',
            style: {
              fontSize: "14px",
              color: '#1ab394'
            }
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            width: [3,3,3,3,3,3,3,3],
            curve: 'straight',
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '100%',
      },
      uninjuryChart: {
        chartOptions: {
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: false,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2
                }
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -10,
                  fontSize: '30px',
                  fontWeight: 'bolder',
                  color: 'rgba(0, 143, 251, 0.85)',
                }
              }
            }
          },
        },
        series: [50],
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
      },
      imprChart: {
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          title: {
            // text: '업무별 개선 현황'
          },
          chart: {
            type: 'bar',
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            show: true,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          colors: [
            '#FFC107', '#00BCD4', '#F44336',
          ],
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10
          },
        },
        series: [
          {
            id: 'actioning',
            name: '조치중',
            data: [],
          },
          {
            id: 'complete',
            name: '개선완료(총합)',
            data: [],
          },
          {
            id: 'overAction',
            name: '지연 건수',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      // imprChart2: {
      //   chartOptions: {
      //     annotations: { 
      //       xaxis: [],
      //     },
      //     title: {
      //       text: ''
      //       // text: '업무별 개선 현황'
      //     },
      //     chart: {
      //       type: 'bar',
      //       stacked: true,
      //     },
      //     plotOptions: {
      //       bar: {
      //         horizontal: false,
      //         columnWidth: '55%',
      //         endingShape: 'rounded'
      //       },
      //     },
      //     grid: {
      //       borderColor: '#e7e7e7',
      //       row: {
      //         colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      //         opacity: 0.5
      //       },
      //     },
      //     dataLabels: {
      //       enabled: true,
      //     },
      //     stroke: {
      //       show: true,
      //     },
      //     xaxis: {
      //       categories: [],
      //     },
      //     yaxis: {
      //       title: {
      //         text: undefined
      //       },
      //     },
      //     tooltip: {
      //       y: {
      //         formatter: function (val) {
      //           return val + " 건"
      //         }
      //       }
      //     },
      //     colors: [
      //       '#FFC107', '#00BCD4', '#F44336',
      //     ],
      //     fill: {
      //       opacity: 1,
      //     },
      //     legend: {
      //       position: 'top',
      //       horizontalAlign: 'left',
      //       offsetX: 10
      //     },
      //   },
      //   series: [
      //     {
      //       id: 'actioning',
      //       name: '조치중',
      //       data: [],
      //     },
      //     {
      //       id: 'complete',
      //       name: '완료',
      //       data: [],
      //     },
      //     {
      //       id: 'overAction',
      //       name: '지연',
      //       data: [],
      //     },
      //   ],
      //   chartWidth: '100%',
      // },
      rate: [],
      safRate: [],
      envRate: [],
      accident: {
        startYmd: '',
        endYmd: '',
      },
      grid1: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            // 작업개요
            label: 'LBL0000211',
            align: 'left',
            sortable: false,
          },
          {
            name: 'swpStepName',
            field: 'swpStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            // 허가일
            label: 'LBL0000213',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            // 허가서구분
            label: 'LBL0000204',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: 'LBLWORK',
            align: 'center',
            style: 'width:120px',
            ellipsis: true,
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            type: "link",
            ellipsis: true,
            sortable: false,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          // {
          //   name: 'actionCompleteRequestDate',
          //   field: 'actionCompleteRequestDate',
          //   label: '조치완료 요청일',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
        data: [],
      },
      gridAcc: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: "iimNearAccidentName",
            field: "iimNearAccidentName",
            // 아차사고명
            label: "LBL0000949",
            align: "left",
            ellipsis: true,
            sortable: true,
            type: "link",
          },
          // {
          //   name: "processName",
          //   field: "processName",
          //   label: "LBLPROCESS",
          //   style: 'width:100px',
          //   align: "center",
          //   sortable: true,
          // },
          {
            name: "iimStatusNearName",
            field: "iimStatusNearName",
            style: 'width:90px',
            // 진행단계
            label: "LBLPROGRESS",
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearKindName",
            field: "iimNearKindName",
            style: 'width:110px',
            // 유형(LBLTYPE) -> 아차사고종류
            label: '아차사고종류',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      gridRisk: {
        listUrl: '',
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서/라인/공정',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: '작업순서',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 분류-유해위험요인
            label: 'LBL0001020',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'beforeRisk',
            field: 'beforeRisk',
            label: '개선전<br/>위험도',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'afterRisk',
            field: 'afterRisk',
            label: '개선후<br/>위험도',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'ibmStepNames',
            field: 'ibmStepNames',
            // 개선
            label: '개선상태',
            align: 'center',
            style: 'width:120px',
            type: 'link',
            sortable: false
          },
        ],
        data: [],
      },
      mapObj: {
        activeTask: 1,
        activeMarked: 0,
        activeMarked2: 0,
        selectedWorkPermit: {
          workPermits: [],
        },
        envUrl: '',
        markInfoUrl: '',
        processUrl: '',
        url: '',
        interval: {
          obj: null,
          seconds: 3,
          isPause: false,
        },
        taskParam: {
          workPermitPeriod: [],
          closedSpaceTypeCd: null,
        },
        maps: [],
        dangerMaps: [],
        orignMaps: [],
        slide: 0,
        height: 800,
        ratio: 1,
        orignRatio: 1,
        endIdx: 1,
        loading: false,
      },
      uninjuryUrl: '',
      rateUrl: '',
      imprProgressUrl: '',
      workProgressUrl: '',
      riskStatusUrl: '',
      actionCompleteRequestDates: [],
      workProgressList: [],
      showTooltopCnt: 0,
      showTooltop: [false,false,false,false,false,false,false,false],
      iconItems: ['school','settings_applications','build','build_circle','restore','manage_accounts','report','report_problem'],
      colorItems2: ['primary','secondary','amber-9','deep-orange','secondary','brown-5','purple','green-7',],
      ibmTaskTypeCdList: [],
      actionCompleteRequestDate: '',
      codeNames: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  activated() {
    this.getMapData();
  },
  deactivated() {
    window.removeEventListener('resize', this.setSize);
    clearInterval(this.mapObj.interval.obj);
    clearInterval(this.interval);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
    clearInterval(this.mapObj.interval.obj);
    clearInterval(this.interval);
  },
  computed: {
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['itemName'];
    },
    showMarkArea() {
      return this.setComplete &&
        ((this.mapObj.otherMaps && this.mapObj.otherMaps.length > 0)  
          || (this.mapObj.maps && this.mapObj.maps.length > 0))
    },
    mapRate() {
      return this.fullscreen ? 0.835 : 0.835;
    },
    swpIconItems() {
      return [
        {
          icon: 'engineering',
          name: '일반',
        },
        {
          icon: 'local_fire_department',
          name: '화기',
        },
        {
          icon: 'dangerous',
          name: '밀폐공간',
        },
        {
          icon: 'flash_off',
          name: '정전',
        },
        {
          icon: 'hardware',
          name: '굴착',
        },
        {
          icon: 'stairs',
          name: '고소',
        },
      ];
    }, 
    setTaskDetailStyle() {
      let _style = {
        width: 0,
        position: 'absolute',
        left: 0,
        top: '35px',
        height: String(575 * this.mapRate + 20) + 'px',
      };
      if (this.isTaskDetailShow) {
        _style = {
          width: '50%',
          // transform: 'translate(100%, 0)',
          position: 'absolute',
          left: 0,
          top: '35px',
          height: String(575 * this.mapRate + 20) + 'px',
        }
      }
      return _style;
    },
    colType1() {
      return !this.isTaskDetailShow ? 'col-3' : 'col-2'
    },
    colType2() {
      return !this.isTaskDetailShow ? 'col-9' : 'col-10'
    },
    colType3() {
      return this.isTaskDetailShow ? 'col-6' : 'col-12'
    },
    colorItems() {
      return [
        // 계획수립
        { stepperMstCd: 'MCSC000005', stepperMstNm: this.$comm.getLangLabel('LBL0000381'), colorClass: 'blue' },
        // 점검중
        { stepperMstCd: 'MCSC000010', stepperMstNm: this.$comm.getLangLabel('LBL0000382'), colorClass: 'orange' },
        // 점검완료
        { stepperMstCd: 'MCSC000015', stepperMstNm: this.$comm.getLangLabel('LBL0000383'), colorClass: 'green' },
        // 지연
        { stepperMstCd: 'MCSC000020', stepperMstNm: this.$comm.getLangLabel('LBL0000384'), colorClass: 'red' },
      ]
    },
  },
  methods: {
    init() {
      this.showTooltopCnt = 0
      this.rateUrl = selectConfig.main.imprChart.url;
      this.imprProgressUrl = selectConfig.main.imprProgress.url;
      this.workProgressUrl = selectConfig.main.workProgress.url;
      this.riskStatusUrl = selectConfig.main.risk.url;
      
      // 지도
      this.mapObj.url = selectConfig.main.map.url
      this.mapObj.processUrl = selectConfig.main.mapProcess.url
      this.mapObj.envUrl = selectConfig.main.env.url
      // 무재해
      this.uninjuryUrl = selectConfig.sai.uninjury.list.url;
      // 지도

      this.actionCompleteRequestDate = this.$comm.getThisYear();

      this.$comm.getComboItems('CLOSED_SPACE_TYPE_CD').then(_result => {
        this.codeNames = _result
      });
      // 년도 설정
      let years = [];
      this.setLangLabel();
      for (let i = 0; i < 5; i++) {
        years.splice(0, 0, this.$comm.getPrevYear(i));
      }
      this.yearOcuurChart.chartOptions.xaxis.categories = years;

      this.accident.startYmd = years[0];
      this.accident.endYmd = years[4];

      this.getImprStatus(null);
      // this.getImprProgress();
      this.getWorkProgress();
      
      this.getMapPriodParam();
      // this.intervalActionMap();
      this.getNearmiss();
      this.getUninjury();
      this.getRiskStatus();

    },
    setSize() {
      setTimeout(() => {
        this.getMapData(true);
      }, 300);
    },
    getUninjury() {
      this.$http.url = this.uninjuryUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: '22700', // 생산본부
        endFlag: 'N'
      }
      this.$http.request((_result) => {
        if (_result.data.length == 1) {
          this.uninjuryData = _result.data[0];
          this.isUninjury = true;
          let _percent = parseInt((parseInt(this.uninjuryData.dateCnt) / parseInt(this.uninjuryData.dateTotalCnt)) * 100)
          this.uninjuryChart.series = [_percent]
          
        } else {
          this.isUninjury = false;
        }
      },);
    },
    setLangLabel() {
      let thisVue = this;
      this.imprChart.series = [
      {
          id: 'actioning',
          name: this.$comm.getLangLabel('LBL0000944'), // 조치중
          data: [],
        },
        {
          id: 'complete',
          name: this.$comm.getLangLabel('LBL0000945'), // 개선완료(총합)
          data: [],
        },
        {
          id: 'overAction',
          name: this.$comm.getLangLabel('LBL0000934'), // 지연 건수
          data: [],
        },
      ]
      this.imprChart.chartOptions.tooltip.y.formatter = (val) => {
        return val + " " + thisVue.$comm.getLangLabel('LBL0000389') //" 건"
      }
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
      },);
    },
    // onClickProcess(mp) {
    //   console.log(mp)
    // },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    clickFullScreen() {
      let element = this.$refs['main-dashborad']
      if (this.fullscreen === false) {
        this.fullscreen = true;
        let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

        if (requestMethod) { // Native full screen.
          requestMethod.call(element);
        }
      } else {
        this.fullscreen = false;
        let requestMethod = document.exitFullscreen || document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || document.msExitFullscreen;
        if (requestMethod) { // Native full screen.
          requestMethod.call(document);
        }		
      }
    },
    getMapData(loading) {
      clearInterval(this.interval);
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
        closedSpaceTypeCd: this.mapObj.taskParam.closedSpaceTypeCd,
      }
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.$set(this.mapObj, 'activeMarked2', 0)
          this.$set(this.mapObj, 'activeMarked', 0)
          /**
           * 지도는 1개
           */
          this.$_.forEach(_result.data, item => {
            this.mapObj.endIdx = 1;
            if (item.maps && item.maps.length > 0) {
              this.$_.forEach(item.maps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
            this.mapObj.closeSpaceEndIdx = 1;
            if (item.dangerMaps && item.dangerMaps.length > 0) {
              this.$_.forEach(item.dangerMaps, map => {
                this.$set(map, 'idx', this.mapObj.closeSpaceEndIdx)
                this.mapObj.closeSpaceEndIdx++;
              })
            }
          })

          this.mapObj.maps = this.$_.cloneDeep(_result.data)
          this.mapObj.orignRatio = this.mapObj.maps[0].ratio;
          
          this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[0].ratio);
          
          this.mapObj.height = this.fullscreen ? 900 : 550
          let resizeRatio = Math.round((this.mapObj.height / (this.fullscreen ? 900 : 575)) * 100) / 100;
          let _height = this.mapObj.height * this.mapRate
          this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
          this.mapObj.ratio = resizeRatio

          // 로딩바 제거
          if (loading) {
            this.mapObj.loading = false;
          }
          let _idx = 0;
          let _idx2 = 0;
          this.interval = setInterval(() => {
            if (this.mapObj.activeTask === 1) {
              if (this.$refs['markedproxy' + (_idx - 1)] !== undefined) {
                this.$refs['markedproxy' + (_idx - 1)][0].hide()
              }
              if (this.$refs['markedproxy' + (_idx)] !== undefined) {
                if (this.mapObj.maps[0].maps[_idx] !== undefined) {
                  this.$set(this.mapObj.selectedWorkPermit, 'workPermits', this.mapObj.maps[0].maps[_idx].resultTypes);
                }
                this.$refs['markedproxy' + _idx][0].show()
              }
              if ((this.mapObj.maps[0].maps.length + 1) == _idx) {
                _idx = 0;
              }
              this.$set(this.mapObj, 'activeMarked', _idx)
              _idx++;
            } else if (this.mapObj.activeTask === 2) {
              if (this.$refs['markedproxy2_' + (_idx2 - 1)] !== undefined) {
                this.$refs['markedproxy2_' + (_idx2 - 1)][0].hide()
              }
              if (this.$refs['markedproxy2_' + (_idx2)] !== undefined) {
                this.$refs['markedproxy2_' + _idx2][0].show()
              }
              if ((this.mapObj.maps[0].dangerMaps.length + 1) == _idx2) {
                _idx2 = 0;
              }
              this.$set(this.mapObj, 'activeMarked2', _idx2)
              _idx2++;
            }
          }, 2000);
        }
      },);
    },
    onMarkedInfo(markedInfo) {
      this.$set(this.mapObj.selectedWorkPermit, 'workPermits', markedInfo.resultTypes);
      this.$set(this.mapObj, 'activeMarked', markedInfo.idx)
    },
    onMarkedInfo2(markedInfo) {
      this.$set(this.mapObj, 'activeMarked2', markedInfo.idx)
    },
    intervalActionMap() {
      this.mapObj.interval.obj = setInterval(() => {
        this.mapObj.interval.seconds--
        if (this.mapObj.interval.seconds === 0) {
          if (this.mapObj.activeTask === 2) {
            this.mapObj.activeTask = 1
          } else {
            this.mapObj.activeTask++;
          }
          this.mapObj.interval.seconds = 5;
        }
      }, 1000);
    },
    /* eslint-disable no-unused-vars */
    getWidth(item) {
      let width = 0;
      this.$_.forEach(item.resultTypes, resultType => {
        width += (resultType.sopWorkTypeName.length * 10) + 60
      })
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + (item.resultTypes.length * 40)
      let detailLocation = item.locationXcoordinate + 250
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 30;
      let calLocation = item.locationYcoordinate + (item.resultTypes.length * 76)
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return !item.isHover ? '30px' : top + 'px'
    },
    workPermitIconName(resultType) {
      let icon = '';
      switch(resultType.sopWorkTypeCd) {
        case 'SPT0000001': // 일반
          icon = 'engineering';
          break;
        case 'SPT0000005': // 화기
          icon = 'local_fire_department';
          break;
        case 'SSWT000001': // 밀폐공간
          icon = 'dangerous';
          break;
        case 'SSWT000005': // 정전
          icon = 'flash_off';
          break;
        case 'SSWT000010': // 방사선
          icon = 'warning';
          break;
        case 'SSWT000015': // 굴착
          icon = 'hardware';
          break;
        case 'SSWT000020': // 고소
          icon = 'stairs';
          break;
        case 'SSWT000025': // 중장비
          icon = 'agriculture';
          break;
      }
      return icon;
    },
    workPermitbadgeColor(swpStepName) {
      let icon = '';
      switch(swpStepName) {
        case '승인완료':
          icon = 'light-blue';
          break;
        case '연장':
          icon = 'amber';
          break;
        case '작업완료':
          icon = 'light-green';
          break;
      }
      return icon;
    },
    setStyleTaskArea(mp) {
      let style = {
        transform: 'translate(' + String(mp.x * this.mapRate) + 'px, ' + String(mp.y * this.mapRate + 30) + 'px)',
        'z-index': 1,
        'user-select': 'auto',
        width: '1px',
        height: '1px',
        position: 'absolute'
      }
      if (mp.isHover) {
        style['z-index'] = 7
      }
      return style;
    },
    openWorkPermitPop(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePermitPopup;
    },
    closePermitPopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    imgHeight(map) {
      return String(map.ratio * 800) + 'px'
    },
    /* eslint-disable no-unused-vars */
    setStepColor(swpStepCd) {
      let color = '';
      switch(swpStepCd) {
        case 'SS00000005':
          color = 'light-blue';
          break;
        case 'SS00000010':
          color = 'amber';
          break;
        case 'SS00000020':
          color = 'light-green';
          break;
      }
      return color;
    },
    setStepClass(swpStepCd) {
      let _class = '';
      switch(swpStepCd) {
        case 'SS00000005':
        _class = 'main-work-permit-card-light-blue';
          break;
        case 'SS00000010':
        _class = 'main-work-permit-card-amber';
          break;
        case 'SS00000020':
        _class = 'main-work-permit-card-light-green';
          break;
      }
      return _class;
    },
    setTypeIcons(sopWorkTypeCds) {
      let icons = [];
      let typeArray = sopWorkTypeCds && sopWorkTypeCds.length > 0 ? sopWorkTypeCds.split(',') : [];
      if (typeArray && typeArray.length > 0) {
        this.$_.forEach(typeArray, type => {
          switch(type) {
            case 'SPT0000001': // 일반
              icons.push('engineering');
              break;
            case 'SPT0000005': // 화기
              icons.push('local_fire_department');
              break;
            case 'SSWT000001': // 밀폐공간
              icons.push('dangerous');
              break;
            case 'SSWT000005': // 정전
              icons.push('flash_off');
              break;
            case 'SSWT000010': // 방사선
              icons.push('warning');
              break;
            case 'SSWT000015': // 굴착
              icons.push('hardware');
              break;
            case 'SSWT000020': // 고소
              icons.push('stairs');
              break;
            case 'SSWT000025': // 중장비
              icons.push('agriculture');
              break;
          }
        })
      }
      return icons;
    },
    openWorkPermit(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    getWorkProgress() {
      this.$http.url = this.workProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd1
      }
      this.$http.request((_result) => {
        this.workProgressList = _result.data
      },);
    },
    getRiskStatus() {
      this.$http.url = this.riskStatusUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        riskYear: this.riskYear,
        risk: this.risk,
      }
      this.$http.request((_result) => {
        this.gridRisk.data = _result.data
      },);
    },
    getImprProgress() {
      this.$http.url = this.imprProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd3,
      };
      this.$http.request((_result) => {
        this.grid2.data = _result.data
      },);
    },
    getNearmiss() {
      this.$http.url = selectConfig.sop.iim.accident.near.list.url; //selectConfig.sop.iim.accident.near.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: '',
        endYmd: '',
      };
      this.$http.request((_result) => {
        this.gridAcc.data = _result.data
      },);
    },
    setChart() {
      setTimeout(() => {
        this.yearOcuurChart.chartWidth = '90%';
      }, 200);
      this.$refs['yearOcuurChart'].refresh();
    },
    datachange4(props) {
      this.getImprStatus();
    },
    getDangerName(_cd) {
    },
    getImprStatus() {
      let dates = [];
      if (this.actionCompleteRequestDate !== null) {
        dates = [this.actionCompleteRequestDate + '-01-01', this.actionCompleteRequestDate + '-12-31'];
      }
      this.$http.url = this.rateUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd4,
        actionCompleteRequestDates: dates,
      }
      this.rate = [];
      this.$http.request((_result) => {
        let taskIdx = 0;
        this.$_.forEach(_result.data, task => {
          this.$_.forEachRight(this.imprChart.series, item => {
            if (!this.rate[taskIdx] && task[item.id] > 0) {
              this.rate[taskIdx] = task.requestTotal > 0 ?
                String(this.$_.round((task.complete / task.requestTotal) * 100)) + '%' : '0%';
            }
          })
          this.ibmTaskTypeCdList.push({
            code: task.ibmTaskTypeCd,
            codeName: task.ibmTaskTypeName,
          });
          taskIdx++;
        })
        this.$_.forEach(this.imprChart.series, item => {
          item.data = this.$_.map(_result.data, item.id)
        })
        let xaxis = this.$_.map(_result.data, 'ibmTaskTypeName');
        let annotations = [];
        this.rate.forEach((r, idx) => {
          // xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: '#775DD0',
              orientation: 'horizontal',
              style: {
                color: '#fff',
                fontSize: '12px',
                background: '#775DD0',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  fontSize: '12px',
                  background: '#775DD0',
                },
                text: '',
              }
            }
          }
        })
        this.imprChart.chartOptions.xaxis.categories = xaxis;
        this.imprChart.chartOptions.annotations.xaxis = annotations;
        
        this.imprChart.chartWidth = '100%';
        this.$refs['imprChart'].refresh();
      },);
    },
    linkClickDanger(row) {
      this.popupOptions.title = "위험물저장소 상세";
      this.popupOptions.param = {
        chmDangerMstId: row ? row.chmDangerMstId : '',
      };
      this.popupOptions.target = () => import(`${"@/pages/chm/danger/dangerMasterDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closeDangerPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList(this.map.sopMapId);
    },
    linkClickAcc(row) {
      this.popupOptions.title = '아차사고 상세'; 
      this.popupOptions.param = {
        iimNearAccidentId: row ? row.iimNearAccidentId : '',
        iimStatusNearCd: row ? row.iimStatusNearCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/near/nearAccidentDetail.vue'}`);
      this.popupOptions.width = "90%"
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkClickRisk(row) {
      if (row.sopImprovementIds) {
        this.popupOptions.title = 'LBLIMPROVE';
        this.popupOptions.param = {
          sopImprovementId: row.sopImprovementIds,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopupRisk;
      }
    },
    closePopupRisk(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getRiskStatus();
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    linkClickImp(row) {
      this.popupOptions.title = 'LBL0000947'; // 개선 상세
      this.popupOptions.param = {
        sopImprovementId: row ? row.sopImprovementId : '',
        ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
      };
      this.popupOptions.width = "80%"
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    selectedBarImp(e, chart, opts) {
      this.popupOptions.width = '90%';
      this.popupOptions.title = "LBL0000948"; // 개선목록 (업무별 현황)
      this.popupOptions.param = {
        plantCd: this.plantCd4,
        ibmTaskTypeCd: this.$_.filter(this.ibmTaskTypeCdList, { codeName: this.imprChart.chartOptions.xaxis.categories[opts.dataPointIndex] })[0].code,
        actionCompleteRequestDates: [this.actionCompleteRequestDate + '-01-01', this.actionCompleteRequestDate + '-12-31']
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/impr.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['gridRisk'].$refs['compo-table'].resetVirtualScroll();
      this.getRiskStatus();
    },
  }
};
</script>
<style lang="sass">

.closedSpace-menu-layer
  border-radius: 10px !important
  max-width: 600px !important
.closedSpace-menu-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  .closedSpace-card-section
    padding: 0 10px 0 10px
  .closedSpace-card-item
    min-height: auto
.closedSpace-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important

.closedSpace-map-list
  padding-right: 0 !important
  .q-item
    flex-direction: column
    align-items: center

.closedSpace-mark-add
  min-width: 0 !important
  padding: 0 !important


.mark-closedSpace
  background: unset !important
  cursor: pointer

.no-padding
  padding: 0px 0px 6px 0px !important
.maindashboard
  .fullscreen-btn
    .q-btn__wrapper
      min-width: 10px !important
      min-height: 10px !important
.maindashboard .cardcontents
  // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important
  // border: 1px solid #cccccc
  
  border-radius: 10px !important
  margin-top: 6px
  margin-bottom: 6px
  margin-right: 6px
  .card-body
    padding: 10px !important
    border-radius: 0 0 10px 10px !important
    background: #fff
  .card-header:before
    font-family: "Material Icons"
    content: "\e876"
  .card-header
    // border-bottom-width: 0px !important
    border-radius: 10px 10px 0 0 !important
    height: 40px
    background: #fff
    color: #008FFB
    display: inline-block
    padding-top: 8px
    width: 100%
    border-bottom: 1px solid #e6e7eb
    .card-more
      float: right !important
      cursor: pointer
      color: #d2d2d2
    .card-more:hover
      color: #1ab394
    .inputClass
      background: #fff !important

.main-header-input
  margin-top: -5px
  padding-right: 5px
.main-header-input2
  margin-top: -5px
  padding-right: 5px
  width: 300px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.cardcontents .card-body.nopadgrid
  padding-top: 0px !important
.btn-fixed-width-height
  width: 95%
  margin: 3px !important
  pointer-events: none

.directorMainLayer
  .workPermit-carousel
    border-radius: 10px
    height: var(--map-height) !important
    .q-carousel__slide
      width: calc(var(--map-ratio) * var(--map-height)) !important
    .q-carousel--padding, .q-carousel__slide
      padding: 0 !important
    .q-carousel__arrow
      transition: all 0.5s
      opacity: 0.2
    .q-carousel__arrow:hover
      transition: all 0.5s
      opacity: 0.8
    .q-carousel__slides-container
      .q-panel.scroll
        overflow: hidden !important
  

.directorMainLayer
  .caption-work2
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #fff
    padding: 10px
  .caption-work
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #555
    margin-top: 4px

.directorMainLayer
  .mainmapstart
    float: right
    margin-right: 0px
    margin-top: -5px

.directorMainLayer
  .mainMarkImage
    padding: 0px !important
    text-align: center
    font-size: 20px
    height: 30px !important
    position: absolute
    padding: 16px
    color: #C10015
    z-index: 3 !important
    // background: rgba(0,0,0,0.47)
    .markImage-abbreviation-parent
      // width:240px
      text-align: start
      position: absolute
      .markImage-abbreviation
        cursor: pointer
        display: inline-block
        // min-width:60px
        // max-width:250px
        width: auto
        border-left: 1px solid #757575
        border-right: 1px solid #757575
        border-top: 1px solid #757575
        border-bottom: 1px solid #757575
        border-radius: 5px
        .markImage-tran
          border-radius: 5px
        .markImage-abbreviation-banner
          border-right: 1px solid #757575
          display: inline-block
          text-align: center
          min-width:60px
          padding: 1px !important
          border-radius: 5px 0px 0px 5px
          i
            font-size: 20px
          div
            font-size: 12px !important
        .markImage-abbreviation-banner.last-banner
          border-right: 0
          border-radius: 0px 5px 5px 0px
        .markImage-abbreviation-plus
          display: inline-block
          text-align: center
          min-width:40px
        .markImage-abbreviation-banner-detail
          border-radius: 5px
          .q-banner__avatar i
            font-size: 20px !important
          .q-banner__content
            font-size: 12px !important

.directorMainLayer
  .markImage-abbreviation > div
    -webkit-animation: fadeInFromNone 0.1s ease-out
    -moz-animation: fadeInFromNone 0.1s ease-out
    -o-animation: fadeInFromNone 0.1s ease-out
    animation: fadeInFromNone 0.1s ease-out

.main-map-card 
  border-radius: 10px !important
  border-width: 0px !important
  background-color: #161d31 !important
  .q-tab--inactive
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
  .q-tab--active
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
    background-color: #fff !important
    color:  #161d31 !important
  .q-tab__indicator
    display: none !important
  .customCardbody
    margin: 0px !important
    .main-card-head-text
      font-weight: 600
      font-size: 0.9rem
      line-height: 26px
  .cardhead
    height: 41px !important
    background-color: #343d55 !important
    color: #fff !important
    padding-top: 5px !important
    font-size: 1.1em !important
  .customCardbody
    background-color: #283046 !important
    border-radius: 0 0 10px 10px !important
    padding-top: 10px !important
  .q-separator--horizontal
    display: none !important
  .mainDashboardTab
    .q-tab-panel
      display: table
      width: 100%
    .q-tab__label
      font-size: 1.0em !important
      font-weight: 600
  .mainDashboardTabS
    .q-tab__label
      font-size: 1.1em !important
  .maintabTable
    width: 100%
    display: inline-flex
    color: #fff !important
  .maintabTableTop
    border-top: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintabTd
    font-size: 1.1em !important
    padding: 8px 5px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%
  .maintabTr
    font-size: 1.1em !important
    padding: 8px 5px
    width: 100%
    background-color: #161d31 !important
  .maintabTd7
    width: 70%
  .maintabTd6
    width: 60%
  .maintabTd5
    width: 50%
  .maintabTd4
    width: 40%
  .maintabTd3
    width: 30%
    text-align: right
  .maintabTd2
    width: 20%
  .maintabTd1
    width: 10%
  .maintabTdgreen
    color: #28c76f !important
    font-size: 1.05em !important
    text-align: center
  .maintablebody
    height: 250px
    background-color: #343d55 !important
    border-radius: 0 0 10px 10px !important
    overflow-y: auto
    border-bottom: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintablebody2
    height: 300px !important
  
.maindashboard thead tr th 
  font-weight:400 !important
.maindashboard .mx-input-wrapper
  input 
    color: #0072c6 !important
    background: white !important
text.apexcharts-xaxis-annotation-label
  fill: black !important
rect 
  stroke: transparent !important
  fill: transparent !important

</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
.my-class {
  /* background-color: #9E9E9E; */
  border: 2px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(40, 48, 70, 0.2);
  border-radius: 10px;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
  color: black;
  cursor: pointer;
  /* z-index: 1 !important; */
}
.my-class div {
  width: 100%;
  border-radius: 10px 10px 0 0;
  color: #fff;
  background-color: rgba(40, 48, 70, 0.6);
}
.my-active-class {
  /* opacity: 0.5; */
  background-color: rgba(115,103,240,0.5);
  /* color:azure; */
}
.chip-task {
  margin-left: 8px !important;
}
.app-main__inner .task-detail {
  z-index: 5;
  width:250px;
  position: relative;
}
.app-main__inner .task-detail .q-banner {
  background-color: #283046;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0px !important;
  color: #fff !important;
}
.app-main__inner .task-detail .q-banner .col-auto {
  padding: 0px !important;
}
.mainmapbannerTitle {
  width: 100%;
  color: #fff;
  background-color: #343d55;
  padding: 10px !important;
  border-radius: 3px;
  font-size: 0.9em !important;
}
.open-task {
  left: 100%; 
  width: 80%;
  transform: translate(-100%, 0);
  position: absolute;
  z-index: 1000;
  padding: 10px !important;
  border-radius: 15px !important;
  background: #fff;
}
.maintablebody::-webkit-scrollbar {
  width: 10px;
}
.maintablebody::-webkit-scrollbar-track {
  background: #343d55 !important;
  border-radius: 0 0 10px 0;
}

.maintablebody::-webkit-scrollbar-thumb {
  background-color: #161d31 !important;
  background-image: linear-gradient(315deg, #161d31 0%, #161d31 74%);
}
.link-line {
  text-decoration: underline;
  cursor: pointer;
}
.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: rgba(0, 143, 251, 0.85) !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 9999;
  font-size: 12px;
}
.mapWorkIcons {
  position: absolute;
  background-color: rgba(220, 221, 221, 0.5) !important;
  border-radius: 10px;
  right: 5px;
  bottom: 5px;
  padding: 5px 10px;
  font-weight: 700;
  line-height: 20px;
  z-index: 9999;
  font-size: 12px;
}
.mapWorkIcons ul {
  list-style-type: none;
  margin-bottom: 0px !important;
}
.uninjury-area {
  padding-top: 10px;
  font-weight: 700;
  font-size: 1.1em;
  width: 100%;
}
.uninjury-title {
  list-style:none;
  float:left;
  width: 40%;
  line-height: 2em;
  text-align: center;
}
.uninjury-data {
  list-style:none;
  float:left;
  width: 60%;
  line-height: 2em;
}
.uninjury-num {
  width: 90px;
  display: inline-block;
  text-align: right;
  background-color: #686666;
  color:yellow !important;
  height: 26px;
  padding-right: 10px;
  line-height: 26px !important;
}
.uninjury-date {
  width: 120px;
  display: inline-block;
  text-align: center;
  background-color: #686666;
  color: #fff;
  height: 26px;
  line-height: 26px !important;
}
</style>